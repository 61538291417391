<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Tiendas" :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true" :scroll="scroll">
      <template v-slot:main>
        <v-form @submit.prevent="filterByNameID">
          <v-row class="mr-5" v-if="$helpers.hasPermission(['view_branch'])" align="center" justify="end" no-gutters>
            <v-col cols="10" class="mr-2">
              <v-text-field v-model="filter" outlined required single-line dense maxlength="60" persistent-hint hide-details />
            </v-col>
            <v-col cols="1">
              <v-btn @click="filterByNameID" text color="blue-500">Filtrar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0">
      <PermissionDenied v-if="!$helpers.hasPermission(['view_branch'])" :isDetail="true" />
      <template v-else>
        <VSkeleton v-if="loading || $store.state.pos.retrieveLoader" />
        <template v-else>
          <!-- branch list -->
          <v-col cols="12" class="px-0 pt-0">
            <v-empty-state v-if="!branchesList?.length" type="branch" id="tiendas" customClass="mt-3" title="No existen tiendas para mostrar" :hiddenDescription="true" :isFree="true">
            </v-empty-state>
            <template v-else>
              <v-row>
                <v-col v-for="(d, index) in branchesList" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                  <v-hover v-slot="{ hover }">
                    <v-card class="px-5 pt-5 pb-1 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(d.branch_id)">
                      <v-card-text class="pa-0">
                        <v-row class="ml-n1" no-gutters align="center">
                          <v-icon class="mt-n2" color="blue-500" size="18">mdi-store</v-icon>
                          <v-spacer />
                          <span class="body-1 grey-300--text mr-n1"><span class="d-inline-block text-truncate" v-if="d.branch_id" style="max-width: 130px;">ID: {{d.branch_id}}</span></span>
                        </v-row>
                        <v-list-item class="mt-n2 ml-n1" three-line>
                          <v-list-item-subtitle>
                            <span class="body-1 grey-500--text" v-if="d.name">{{d.name}}</span>
                            <span class="body-2 text--disabled font-italic" v-if="!d.name">Sin especificar</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <!-- <v-col class="pa-0">
                <VMainPagination :count="count" />
              </v-col> -->
            </template>
          </v-col>
          <!-- end branch list -->
        </template>
      </template>
    </v-col>

    <!-- dialog new branch -->
    <v-dialog v-model="dialogBranch" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="px-4" color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Crear nueva tienda</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogBranch=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-0 px-4">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model.trim="$v.newBranch.name.$model" :error="$v.newBranch.name.$error" outlined required single-line dense placeholder="Acme LLC" maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Dirección</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.newBranch.address.$model" :error="$v.newBranch.address.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>{{$t('generals.Comuna')}}</v-list-item-title>
              <v-list-item-subtitle v-if="$store.getters['base/currentStates']">
                <v-select v-model="$v.newBranch.state.$model" :error="$v.newBranch.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates'] || []" item-value="id" item-text="name" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogBranch=false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createBranch">Crear nueva tienda</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new branch -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    VEmptyState,
    VSkeleton,
    VHeader,
    VQueryBuilder,
    VMainPagination,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    backupBranches: [],
    filter: '',
    posList: [],
    activeFilter: false,
    scroll: 0,
    loading: false,
    chipStatus: {
      active: {
        name: 'Activo',
        icon: 'mdi-check',
        color: 'lightBlue'
      },
      issued: {
        name: 'Con problemas',
        icon: 'mdi-close',
        color: 'redLight'
      },
      off: {
        name: 'Apagado',
        icon: 'mdi-cloud-off-outline',
        color: 'greyMedium'
      }
    },
    filters: [
      {
        id: 'name',
        label: 'Nombre',
        operators: ['contiene'],
        type: 'text',
        category: 'a',
        value: null
      }
      // {
      //   id: 'state',
      //   label: 'Comuna',
      //   choices: [],
      //   type: 'select',
      //   category: 'b',
      //   value: null
      // }
    ],
    dialogBranch: false,
    newBranch: {
      name: '',
      state: '',
      address: ''
    },
    currentSort: {
      label: 'Nombre',
      id: 'ascending_name'
    },
    sortList: [
      {
        label: 'Nombre',
        id: 'ascending_name'
      },
      {
        label: 'Nombre',
        id: 'descending _name'
      },
      {
        label: 'Comuna',
        id: 'ascending_state'
      },
      {
        label: 'Comuna',
        id: 'descending _state'
      }
    ],
    breadcrumbs: {
      main: 'Tiendas',
      children: []
    },
    branchesList: [],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    ...mapState({
      count: state => state.branches.branchesCount
    }),
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.branchesList?.length) return false
      return false
    }
  },
  created () {
    if (this.$helpers.hasPermission(['view_branch'])) this.getList()
    // this.setFilterComuna()
    // this.filters[1].label = this.$t('generals.Comuna')
  },
  watch: {
    $route: {
      handler (val) {
        if (Object.keys(val.query).includes('name__icontains')) {
          this.branchesList = this.branchesList.filter(({name}) => val.query.name__icontains.toLowerCase().includes(name.toLowerCase()))
        } else this.getList()
      },
      inmediate: true,
      deep: true
    },
    filter (val) {
      if (!val.length) this.branchesList = [...this.backupBranches]
    }
  },
  methods: {
    filterByNameID () {
      this.branchesList = [...this.branchesList.filter((item) => {
      if (this.filter?.length > 0) {
          return item.branch_id.toUpperCase().includes(this.filter.toUpperCase()) || item.name.toUpperCase().includes(this.filter.toUpperCase())
        }
        return false
      })]
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    setFilterComuna () {
      const index = this.filters.findIndex(({id}) => id === 'state')
      this.filters[index].choices = this.$store.getters['base/currentStates'].map(({name, id}) => {
        return {
          label: name,
          value: id
        }
      })
    },
    getList () {
      this.loading = true
      this.$store.dispatch('branches/LIST', {
        resource: 'pos-app/branches',
        query: this.$route.query
      })
      .then((resp) => {
        this.branchesList = resp.data
        this.backupBranches = [...this.branchesList]
      })
      .finally(() => {
        this.loading = false
      })
    },
    createBranch () {
      this.$v.newBranch.$touch()
      if (this.$v.newBranch.$invalid) {
        return false
      }
      this.$store.dispatch('branches/CREATE', {
        resource: 'pos-app/branches',
        payload: this.newBranch
      })
      .then(() => {
        this.$dialog.message.info('Tienda guardada correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.dialogBranch = false
      })
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'BranchRetrieveUpdate', params: { id: id, ...this.currentParams } })
    }
  },
  validations: {
    newBranch: {
      name: { required },
      state: { required },
      address: { required, maxLength: maxLength(254) }
    }
  }
}
</script>
<style>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 3 !important;
}
</style>